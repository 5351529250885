.headerList a {
  font-family: "Hugolers";
  font-size: medium;
}

.headerList button {
  font-family: "Hugolers";
  font-size: medium;
}

.headerList a:hover {
  color: white !important;
}

.headerList a:active {
  color: white !important;
}

.sectionsHeader {
  margin-top: 500px;
}
