@font-face {
  font-family: "AvenirNextCondensed";
  font-style: normal;
  font-weight: 400;
  src: local("AvenirNextCondensed"),
    url("../../fonts/AvenirLight.otf") format("opentype");
}

@font-face {
  font-family: "AvenirNextCondensed";
  font-style: normal;
  font-weight: 900;
  src: local("AvenirNextCondensed"),
    url("../../fonts/AvenirBold.otf") format("opentype");
}

@font-face {
  font-family: "HugolersStylishRegular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Hugolers.otf") format("opentype");
}
